import React from 'react'
import { list } from 'aws-amplify/storage';
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'

type FetchFilesList = DownloadStatusType<string[]>

export const useListResultFiles = (rigType: string, datePrefix: string): FetchFilesList => {
    const [filesList, setFilesList] = React.useState<FetchFilesList>({type: DownloadStatusTypeString.LOADING})

    React.useEffect(() => {
        const fetchFileList = async () => {
            setFilesList({type: DownloadStatusTypeString.LOADING})
            try {
                const parts = datePrefix.split("-")
                const yearMonth = `${parts[0]}-${parts[1]}`
                const yearMonthDay = `${parts[0]}-${parts[1]}-${parts[2]}`
                const prefix = `${rigType}Tests/${yearMonth}/${yearMonthDay}/${rigType}Test-${datePrefix}`
                const listResult = await list({prefix: prefix})
                const filepaths = listResult.items.map(item => {
                    const filepath = item.key as string
                    const filepathWithoutJson = filepath.replace(".json", "");
                    return filepathWithoutJson
                })
                setFilesList({type: DownloadStatusTypeString.LOADED, results: filepaths})
            } catch (error: any) {
                setFilesList({type: DownloadStatusTypeString.ERROR, errorString: error.toString()})
            }
        }
        fetchFileList()
    }, [datePrefix, rigType])

    return filesList
}