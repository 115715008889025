import React from 'react'
import { useConfigurationFiles, ConfigurationFileType } from "../hooks/useConfigurationFiles"
import { NewConfigurationModal } from "./NewConfiguration"
import { DownloadStatusTypeString } from "../utilities/DownloadStatusType";
import { Button, Grid, Card, Table, TableHead, TableBody, TableCell, TableRow, Alert } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { FaFile } from "react-icons/fa";

export const Configurations = () => {
  const [newConfigurationIndex, setNewConfigurationIndex] = React.useState(0)
  const configurations = useConfigurationFiles(newConfigurationIndex)


  const FileList = ({ configurations }: { configurations: ConfigurationFileType[] }) => {
    const [newConfigurationDisplayed, setNewConfigurationDisplayed] = React.useState(false);
  
    const showNewFile = () => {
      setNewConfigurationDisplayed(true)
    }
  
    let allConfigurationNames = configurations.map((configuration) => configuration.name)
  
    let links = configurations.map((configuration) => {
      return <TableRow key={configuration.name}>
        <TableCell>
          <Link to={`/configuration/${configuration.name}`}>{configuration.name}</Link>
        </TableCell>
        <TableCell>
          {configuration.lastUpdated.toLocaleString()}
        </TableCell>
      </TableRow>
    })
    return <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="1fr auto" className="container">
      <NewConfigurationModal active={newConfigurationDisplayed} onClose={() => setNewConfigurationDisplayed(false)} existingConfigurationFiles={allConfigurationNames} onNewConfiguration={() => {setNewConfigurationIndex(newConfigurationIndex + 1)}} />
      <Card columnStart="1" columnEnd="1">
        <Table variation="striped" highlightOnHover={true}>
          <TableHead>
            <TableRow>
              <TableCell as="th">Name</TableCell>
              <TableCell as="th">Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {links}
          </TableBody>
        </Table>
      </Card>
      <Card columnStart="2" columnEnd="2">
        <Button variation="primary" onClick={() => showNewFile()}>New Configuration <FaFile style={{ marginLeft: "0.5em" }} /></Button><br /><br />
        <a href="https://documentation.m3handheld.com/iPhone_software/Configuration/index.html" target="_blank" rel="noreferrer">Configuration file documentation</a>
      </Card>
    </Grid>
  }  

  let contentToRender = <></>

  switch (configurations.type) {
    case DownloadStatusTypeString.LOADING:
      contentToRender = <Alert variation="info">Loading...</Alert>
      break;
    case DownloadStatusTypeString.ERROR:
      contentToRender = <Alert variation="error">{configurations.errorString}</Alert>
      break;
    case DownloadStatusTypeString.LOADED:
      if (configurations.results.length === 0) {
        contentToRender = <Alert variation="info">No configuration files have been uploaded</Alert>
      } else {
        contentToRender = <FileList configurations={configurations.results} />
      }
      break;
  }

  return contentToRender
}
