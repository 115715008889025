import React from 'react'
import { useConfigurationFile } from '../hooks/useConfigurationFile'
import { useConfigurationValidation } from '../hooks/validation/useConfigurationValidation'
import { ValidationError } from '../hooks/validation/ValidationError'
import { archiveConfiguration } from '../server/archiveConfiguration'
import { DownloadStatusTypeString } from "../utilities/DownloadStatusType"
import { initiateDataDownload } from "../utilities/DownloadUtilities"
import { Heading, Grid, Card, View, Button, Alert, DropZone, Flex, Text, useTheme } from "@aws-amplify/ui-react"
import { useParams, useNavigate } from 'react-router-dom'

import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/stackoverflow-light.css'

import { FaTrash, FaFileDownload, FaFileUpload } from "react-icons/fa";
import { MdCheckCircle, MdFileUpload, MdRemoveCircle } from 'react-icons/md';
import { updateConfiguration } from '../server/updateConfiguration'
import { Configuration as ConfigurationType } from '../types/Configuration'

hljs.registerLanguage('json', json)

export const Configuration = () => {
    const { filename } = useParams()
    const [changeIndex, setChangeIndex] = React.useState(0)
    const configuration = useConfigurationFile<ConfigurationType>(filename!, changeIndex)
    const [errors, validateConfiguration] = useConfigurationValidation(configuration)

    const onFileUploaded = async (fileContents: string) => {
        const errors = validateConfiguration(fileContents)
        if (errors.length === 0) {
            await updateConfiguration(filename!, fileContents)
            setChangeIndex(changeIndex + 1)
        }
    }

    let contentToRender = <></>;
    switch (configuration.type) {
        case DownloadStatusTypeString.LOADING:
            contentToRender = <Alert variation="info">Loading...</Alert>
            break
        case DownloadStatusTypeString.ERROR:
            contentToRender = <Alert variation="error">Error downloading '{filename}.json'; {configuration.errorString}</Alert>
            break
        case DownloadStatusTypeString.LOADED:
            contentToRender = <ConfigurationFileRenderer
                filename={filename!}
                configuration={configuration.results}
                onFileUploaded={onFileUploaded}
                errors={errors} />
            break
    }

    return (
        <View>
            {contentToRender}
        </View>
    );
}

const BackLink = () => {
    const navigate = useNavigate();
    return <Button onClick={() => navigate(-1)}>&lt; Back</Button>
}

const ConfigurationFileRenderer = ({ filename, configuration, onFileUploaded, errors }: { filename: string, configuration: any, onFileUploaded: CallableFunction, errors: ValidationError[] }) => {
    const { tokens } = useTheme()

    const jsonString = JSON.stringify(configuration, null, 2)
    const navigate = useNavigate()
    const [showUploadOverlay, setShowUploadOverlay] = React.useState(false)

    const archiveConfigurationAndNavigationBack = async (filename: string) => {
        await archiveConfiguration(filename)
        navigate(-1)
    }

    const onFileUploadedWrapper = async (fileContents: string) => {
        await onFileUploaded(fileContents)
        setShowUploadOverlay(false)
    }

    return (
        <Grid templateColumns="auto 1fr 1fr">
            <Card columnStart="1" columnEnd="1">
                <BackLink />
            </Card>
            <Card columnStart="2" columnEnd="2">
                <Heading level={3}>{filename}</Heading>
            </Card>
            <Card columnStart="3" columnEnd="3">
                <Grid justifyContent="right" templateRows="auto" >
                    <Card columnStart="1" columnEnd="-1" style={{ padding: "0" }}>
                        <Button variation="primary" onClick={() => downloadConfiguration(filename, jsonString)} style={{ marginLeft: '0.5em' }}>Download <FaFileDownload style={{ marginLeft: "0.5em" }} /></Button>
                        <Button variation="primary" onClick={() => archiveConfigurationAndNavigationBack(filename)} colorTheme="warning" style={{ marginLeft: '0.5em' }}>Archive <FaTrash style={{ marginLeft: "0.5em" }} /></Button>
                        <Button variation="primary" onClick={() => setShowUploadOverlay(!showUploadOverlay)} style={{ marginLeft: '0.5em' }}>Upload <FaFileUpload style={{ marginLeft: "0.5em" }} /></Button>
                    </Card>
                </Grid>
            </Card>
            <Card columnStart="1" columnEnd="-1">
                {
                    !showUploadOverlay && errors.map(error =>
                        <Card columnStart="1" columnEnd="-1" marginTop="0.5rem" backgroundColor={tokens.colors.red[20]}>
                            {error.message} {error.cause instanceof Error ? error.cause.message : ""}
                        </Card>
                    )
                }
            </Card>
            <Card columnStart="1" columnEnd="-1">
                <RenderJson jsonString={jsonString} showUploadOverlay={showUploadOverlay} onFileUploaded={onFileUploadedWrapper}/>
            </Card>
        </Grid>
    )
}

const RenderJson = ({ jsonString, showUploadOverlay, onFileUploaded }: { jsonString: string, showUploadOverlay: boolean, onFileUploaded: CallableFunction }) => {
    const codeBlockRef = React.useRef(null);

    React.useEffect(() => {
        hljs.highlightBlock(codeBlockRef.current!);
    }, [codeBlockRef]);

    const acceptedFileTypes = ['application/json'];

    const onFileUploadedWrapper = async ({ acceptedFiles }: { acceptedFiles: any[]}) => {
        if (acceptedFiles.length === 0) {
            return;
        }
        const file = acceptedFiles[0];
        const jsonFileString = await file.text(); // if this was a large file, we would want to stream this instead of loading it all into memory
        onFileUploaded(jsonFileString);
    }

    return (
        <>
            {showUploadOverlay && <DropZone
                acceptedFileTypes={acceptedFileTypes}
                onDropComplete={onFileUploadedWrapper} >
                <Flex direction="row" justifyContent="center" alignItems="center">
                    <DropZone.Accepted>
                        <MdCheckCircle fontSize="2rem" />
                    </DropZone.Accepted>
                    <DropZone.Rejected>
                        <MdRemoveCircle fontSize="2rem" />
                    </DropZone.Rejected>
                    <DropZone.Default>
                        <MdFileUpload fontSize="2rem" />
                    </DropZone.Default>
                    <Text>Drag and drop your configuration json here.</Text>
                </Flex>
            </DropZone>
            }
            <div className="code-block-container">
                <pre >
                    <code ref={codeBlockRef} className="language-json">
                        {jsonString}
                    </code>
                </pre>

                {showUploadOverlay && <div className="configuration-overlay">&nbsp;</div>}
            </div>
        </>
    )
}

const downloadConfiguration = (filename: string, configuration: any) => {
    initiateDataDownload(configuration, `${filename}.json`, "application/json")
}

