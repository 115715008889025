import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";

export class SpotsError extends ValidationError {}

/**
 * Checks if the channel spots defined are correct (within range of the sensor configuration),
 * with no duplicate spot definitions.
 * @param configuration The configuration object.
 * @throws SpotsError
 */
export const spotsCorrect = (configuration: Configuration): ValidationError[] => {
    const errors: ValidationError[] = []

    const numColumns = configuration.sensorConfiguration.numColumns
    const numRows = configuration.sensorConfiguration.numRows

    if (!numColumns || !numRows) {
        return [new SpotsError("The number of columns or rows in the sensor configuration were missing or an invalid value.")]
    }
    if (numColumns < 0 || numRows < 0) {
        return [new SpotsError("The number of columns or rows in the sensor configuration were invalid because they were negative.")]
    }

    if (configuration.channelConfiguration.length === 0) {
        return [new SpotsError("Channel configuration was empty")]
    }

    const maxSpotNumber = numColumns * numRows

    const usedSpotNumbers: { [spotNumber: number]: boolean } = {}

    configuration.channelConfiguration.forEach(channel => {
        channel.spotNumbers.forEach(spotNumber => {
            if (spotNumber > maxSpotNumber || spotNumber < 1) {
                errors.push(
                    new SpotsError(`Channel "${channel.name}" had a spot number "${spotNumber}" which is outside the range defined by sensor configuration`)
                )
            }

            if (spotNumber in usedSpotNumbers) {
                errors.push(
                    new SpotsError(`Channel "${channel.name}" had a spot number "${spotNumber}" that has already been taken`)
                )
            }

            usedSpotNumbers[spotNumber] = true
        })
    })

    return errors
}
