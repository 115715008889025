import { Heading, View, Message } from "@aws-amplify/ui-react"
import '@aws-amplify/ui-react/styles.css'
import { useRouteError } from "react-router-dom"

export const ErrorPage = () => {
  const error:any = useRouteError();
  console.error(error);

  return (
    <View id="error-page">
      <Heading level={1}>Oops</Heading>
      <Message colorTheme="error">
        Sorry, an unexpected error has occurred.
        <br />
        <i>{error.statusText || error.message}</i>
      </Message>
    </View>
  );
}