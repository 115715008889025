import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef } from "react";
import { DownloadStatusType, DownloadStatusTypeString } from "../../utilities/DownloadStatusType";
import { IntensityResult } from "../../types/flowTestResult";

Chart.register(annotationPlugin)

export const IntensityChart = ({ resultFile }: {resultFile: DownloadStatusType<[IntensityResult]>}) => {
    const chartContextRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (resultFile.type === DownloadStatusTypeString.LOADED) {
            const data = resultFile.results

            const channelData = data.reduce<{ [channel: string]: [number] }>((currentChannelData, entry) => {
                if (entry.channel in currentChannelData) {
                    currentChannelData[entry.channel].push(entry.average)
                } else {
                    currentChannelData[entry.channel] = [entry.average]
                }
                return currentChannelData
            }, {})

            const dataset = Object.keys(channelData).map((channel) => {
                const averages = channelData[channel]
                return {
                    label: channel,
                    data: averages,
                    fill: false,
                    // borderColor: "rgb(192, 192, 75)",
                    tension: 0.1,
                    yAxisID: 'y',
                }
            })

            const justTimesWithoutDuplicated =  data.reduce<number[]>(
                (accumulator: number[], item) => {
                    if (accumulator.length === 0 || accumulator[accumulator.length - 1] !== item.time) {
                        accumulator.push(item.time);
                    }
                    return accumulator
                },
                []
            )
            const chartData = {
                labels: justTimesWithoutDuplicated,
                datasets: dataset
            }

            const chartOptions = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Time (ms)",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Intensity",
                        },
                    }
                },
                animation: {
                    duration: 0,
                },
                plugins: {
                    annotation: {}
                }
            };

            const chartContext = ((chartContextRef.current) as any).getContext("2d");
            chartRef.current = (new Chart(chartContext, {
                type: "line",
                data: chartData,
                options: chartOptions as any,
            }) as any)

            return () => {
                if (chartRef.current) {
                    (chartRef.current as any).destroy();
                    chartRef.current = null;
                }
            }
        }
    }, [resultFile]);

    return <canvas id="chartRef" ref={chartContextRef} width={400} height={100} />
}
