import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";
import { validateRoutine } from "./utilities";

export const detectChangeInDegasserEffort = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = validateRoutine(configuration, "DetectChangeInDegasserEffort", {
        threshold: ["number", (value: any) => []] // Can be any number (positive and negative)
    })

    return errors
}
