import { configAmplifyForConfigurationsBucket, configAmplifyForUploadBucket } from '../authentication/amplifyV6-azure-cognito-configuration'
import { uploadData } from 'aws-amplify/storage';

export const updateConfiguration = async (configurationFilename: string, fileContents: string) => {
    try {
        configAmplifyForConfigurationsBucket()
        await uploadData({
            key: `active/${configurationFilename}.json`,
            data: fileContents
        }).result
    } catch (error: any) {
        console.error("error: ", error)
    } finally {
        configAmplifyForUploadBucket() // restore the original S3 bucket configuration
    }
}