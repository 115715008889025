import { configAmplifyForConfigurationsBucket, configAmplifyForUploadBucket } from '../authentication/amplifyV6-azure-cognito-configuration'
import { copy, remove } from 'aws-amplify/storage';

export const archiveConfiguration = async (filename: string) => {
    try {
        configAmplifyForConfigurationsBucket()
        await copy({
            source: { key: `active/${filename}.json`},
            destination: { key: `archive/${filename}.json` }
        })
        await remove({ key: `active/${filename}.json` })
    } catch (error: any) {
        console.error("error: ", error)
    } finally {
        configAmplifyForUploadBucket() // restore the original S3 bucket configuration
    }
}