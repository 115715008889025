import React from 'react'
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'
import { downloadJsonFileFromS3 } from '../utilities/DownloadUtilities'

export const useRigResultFile = <T>(filepath: string): DownloadStatusType<T> => {
    const [resultFile, setResultFile] = React.useState<DownloadStatusType<T>>({type: DownloadStatusTypeString.LOADING})

    React.useEffect(() => {
        const fetchResultFile = async () => {
            setResultFile({type: DownloadStatusTypeString.LOADING})
            try {
                const result = await downloadJsonFileFromS3<T>(`${filepath}.json`)
                setResultFile({type: DownloadStatusTypeString.LOADED, results: result})
            } catch (error: any) {
                setResultFile({type: DownloadStatusTypeString.ERROR, errorString:error.toString()})
            }
        }
        fetchResultFile()
    }, [filepath])

    return resultFile
}