import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { Grid, Card, Tabs } from "@aws-amplify/ui-react";
import { useNavigate, useMatch } from "react-router-dom";
import { TopbarNav } from "../views/TopbarNav";
import { useRequestFromURLParams } from "../hooks/useRequestType";
import { OverviewFileList } from '../views/OverviewFileList';
import { Configurations } from '../views/Configurations';

export const App = () => {
  const request = useRequestFromURLParams()
  const configurationMatch = useMatch('/configurations/:dateString')
  const navigate = useNavigate()

  const handleTabChange = (tab: string) => {
    if (tab === "pressureRig" || tab === "chipflow") {
      navigate(`/overview/${tab}/${request.dateString}`)
    } else if (tab === "configurations") {
      navigate(`/configurations/${request.dateString}`)
    }
  }

  return (
    <>
      <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="1fr 1fr 1fr" templateRows="auto 1fr">
        <Card columnStart="1" columnEnd="-1">
          <TopbarNav request={request} />
        </Card>

        <Card columnStart="1" columnEnd="-1">
          <Tabs.Container 
            isLazy={true}
            defaultValue="pressureRig" 
            value={configurationMatch ? 'configurations' : request.rigType}
            onValueChange={handleTabChange}>
            <Tabs.List>
              <Tabs.Item value="pressureRig">
                Pressure Rig
              </Tabs.Item>
              <Tabs.Item value="chipflow">
                Flow Tests
              </Tabs.Item>
              <Tabs.Item value="configurations">
                Configurations
              </Tabs.Item>
            </Tabs.List>
            <Tabs.Panel value="pressureRig"><OverviewFileList /></Tabs.Panel>
            <Tabs.Panel value="chipflow"><OverviewFileList /></Tabs.Panel>
            <Tabs.Panel value="configurations"><Configurations /></Tabs.Panel>
          </Tabs.Container>
        </Card>
      </Grid>
    </>
  );
}
