import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";

export const invalidNames = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        throw new ValidationError("Assay sequence was empty")
    }

    const errors: ValidationError[] = []

    const mathematicalCharacters = ['+', '-', '*', '/', '^', '(', ')']
    
    configuration.assaySequence.forEach(sequence => {
        if (sequence.name.length === 0) {
            errors.push(new ValidationError(`Sequence name cannot be empty`))
        }

        if (sequence.name.includes(' ')) {
            errors.push(new ValidationError(`Sequence name "${sequence.name}" contains invalid character " " (spaces are not allowed, use underscores)`))
        }

        mathematicalCharacters.forEach(character => {
            if (sequence.name.includes(character)) {
                errors.push(new ValidationError(`Sequence name "${sequence.name}" contains invalid character "${character}" (mathematical characters are not allowed)`))
            }
        })
    })

    configuration.calculatedValues.forEach(calculatedValue => {
        if (calculatedValue.name.length === 0) {
            errors.push(new ValidationError(`Calculated value name cannot be empty`))
        }

        if (calculatedValue.name.includes(' ')) {
            errors.push(new ValidationError(`Calculated value name "${calculatedValue.name}" contains invalid character " " (spaces are not allowed, use underscores)`))
        }

        mathematicalCharacters.forEach(character => {
            if (calculatedValue.name.includes(character)) {
                errors.push(new ValidationError(`Calculated value name "${calculatedValue.name}" contains invalid character "${character}" (mathematical characters are not allowed)`))
            }
        })
    })

    configuration.channelConfiguration.forEach(channel => {
        if (channel.name.length === 0) {
            errors.push(new ValidationError(`Channel name cannot be empty`))
        }

        if (channel.name.includes(' ')) {
            errors.push(new ValidationError(`Channel name "${channel.name}" contains invalid character " " (spaces are not allowed, use underscores)`))
        }

        mathematicalCharacters.forEach(character => {
            if (channel.name.includes(character)) {
                errors.push(new ValidationError(`Channel name "${channel.name}" contains invalid character "${character}" (mathematical characters are not allowed)`))
            }
        })
    })

    return errors
}
