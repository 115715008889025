import React from 'react'
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'
import { downloadJsonFileFromS3 } from '../utilities/DownloadUtilities'
import { configAmplifyForConfigurationsBucket, configAmplifyForUploadBucket } from '../authentication/amplifyV6-azure-cognito-configuration'

export const useConfigurationFile = <T>(filename: string, changeIndex: number): DownloadStatusType<T> => {
    const [resultFile, setResultFile] = React.useState<DownloadStatusType<T>>({type: DownloadStatusTypeString.LOADING})

    React.useEffect(() => {
        const fetchResultFile = async () => {
            setResultFile({type: DownloadStatusTypeString.LOADING})
            try {
                configAmplifyForConfigurationsBucket()
                const result = await downloadJsonFileFromS3<T>(`active/${filename}.json`)
                setResultFile({type: DownloadStatusTypeString.LOADED, results: result})
            } catch (error: any) {
                setResultFile({type: DownloadStatusTypeString.ERROR, errorString:error.toString()})
            } finally {
                configAmplifyForUploadBucket() // restore the original S3 bucket configuration
            }
        }
        fetchResultFile()
    }, [filename, changeIndex])

    return resultFile
}
