// YYY-MM-DD
export const formatDateYYYYMMDD = (date: Date, joiner: string = "-"): string => {
    const ensureTwoDigits = (num: number) : string => {
      return num.toLocaleString('en-US', {minimumIntegerDigits: 2})
    }
    const dateElements = [date.getFullYear().toString(), ensureTwoDigits(date.getMonth() + 1), ensureTwoDigits(date.getDate())]
    return dateElements.join(joiner)  
}

export const formatTime = (date: Date): string => {
  const ensureTwoDigits = (num: number) : string => {
    return num.toLocaleString('en-US', {minimumIntegerDigits: 2})
  }
  return`${ensureTwoDigits(date.getHours())}:${ensureTwoDigits(date.getMinutes())}` 
}

export const formatDateHumanReadable = (isoDate: string) : string => {
  const date = new Date(isoDate)
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}