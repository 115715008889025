// grr  Amplify UI components do not include a modal dialog.  I based this on:
// https://dev.to/aws-builders/step-by-step-build-your-first-aws-amplify-and-react-app-1agn
// https://github.com/vanhoangkha/amplify-notes

import React from 'react'
import { TextField, Grid, Card, Heading, Button, Divider, Flex, Icon, SelectField } from "@aws-amplify/ui-react";
import { createNewConfiguration } from '../server/createNewConfiguration';


interface NewConfigurationModalProps {
    active: boolean
    onClose: CallableFunction
    existingConfigurationFiles: string[]
    onNewConfiguration: CallableFunction
}

export const NewConfigurationModal = (props: NewConfigurationModalProps) => {
    const [configurationNameTextEntryShowError, setConfigurationNameTextEntryShowError] = React.useState(false);
    const [configurationNameText, setconfigurationNameText] = React.useState("");
    const [basedOnConfiguration, setBasedOnConfiguration] = React.useState(props.existingConfigurationFiles[0]);

    const checkConfigurationAndSubmit = async ()  => {
        console.log(configurationNameText, basedOnConfiguration)
        if (configurationNameText === "") {
            setConfigurationNameTextEntryShowError(true)
            return
        }
        await createNewConfiguration(configurationNameText, basedOnConfiguration)
        props.onNewConfiguration()
        resetAndClose()
    }

    const resetAndClose = () => {
        setconfigurationNameText("")
        setBasedOnConfiguration(props.existingConfigurationFiles[0])
        setConfigurationNameTextEntryShowError(false)

        props.onClose()
    }

    return <div className='modal' style={{ display: props.active ? 'block' : 'none' }}>
        <Flex
            gap="16px"
            direction="column"
            width="640px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
        >
            <Flex
                gap="24px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="24px 24px 24px 24px"
            >
                <Flex
                    gap="10px"
                    direction="row"
                    width="unset"
                    height="unset"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="5px 5px 5px 5px"
                >
                    <Heading
                        width='65vw'
                        level={5}
                    >New Configuration</Heading>

                    <Icon
                        width="14px"
                        height="14px"
                        viewBox={{ minX: 0, minY: 0, width: 14, height: 14 }}
                        paths={[
                            {
                                d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
                                fill: "rgba(13,26,38,1)",
                                fillRule: "nonzero",
                            },
                        ]}
                        display="block"
                        shrink="0"
                        position="relative"
                        onClick={() => props.onClose()} />
                </Flex>
                <TextField
                    label="Configuration name"
                    value={configurationNameText}
                    errorMessage="configuration name cannot be blank"
                    isRequired={true}
                    hasError={configurationNameTextEntryShowError}
                    onChange={(event) => { setconfigurationNameText(event.target.value)}}
                />
                <SelectField label="Based on" 
                    value={basedOnConfiguration}
                    onChange={(event) => setBasedOnConfiguration(event.target.value)}>
                    {
                        props.existingConfigurationFiles.map((name) => {
                            return <option value={name} key={name}>{name}</option>
                        })
                    }
                </SelectField>

                <Divider />

                <Grid>
                    <Card columnStart="1" columnEnd="-1" style={{ padding: "0" }}>
                        <Button variation="primary" onClick={() => checkConfigurationAndSubmit()}>Create Configuration</Button>
                        <Button variation="primary" colorTheme="warning" onClick={() => resetAndClose()} style={{ marginLeft: '0.5em' }}>Close</Button>
                    </Card>
                </Grid>
            </Flex>
        </Flex>
    </div>
}


