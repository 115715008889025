import { Heading, Grid, Card, View, Button, Alert } from "@aws-amplify/ui-react"
import '@aws-amplify/ui-react/styles.css';
import { DownloadStatusTypeString } from "../utilities/DownloadStatusType"
import { useRigResultFile } from "../hooks/useRigResultFile"
import { useEffect, useRef } from "react";
import { Chart } from "chart.js/auto";
import { useParams, useNavigate } from 'react-router-dom'
import { PressureRigResult } from '../types/pressureRigResult'

export const ResultPressureRig = () => {
    const { yearMonth, dateString, filename } = useParams();
    const filepath = `pressureRigTests/${yearMonth}/${dateString}/${filename}`

    const resultFile = useRigResultFile<PressureRigResult>(filepath)
    const pressureChartContextRef = useRef(null);
    const effortChartContextRef = useRef(null);
    const pressureChartRef = useRef(null);
    const effortChartRef = useRef(null);

    const BackLink = () => {
        const navigate = useNavigate();
        return <Button onClick={() => navigate(-1)}>&lt; Back</Button>
    }

    useEffect(() => {
        if (resultFile.type === DownloadStatusTypeString.LOADED) {
            const results = resultFile.results.results
            const pressureChartData = {
                labels: Array.from(results.keys()),

                datasets: [
                    {
                        label: "Internal Flow Pump Pressure",
                        data: results.map((result) => result.internalFlowPumpPressure),
                        fill: false,
                        // borderColor: "rgb(75, 192, 192)",
                        tension: 0.1,
                    },
                    {
                        label: "External Flow Pump Pressure",
                        data: results.map((result) => result.externalFlowPumpPressure),
                        fill: false,
                        // borderColor: "rgb(192, 75, 192)",
                        tension: 0.1,
                    },
                    {
                        label: "Internal Degasser Pump Pressure",
                        data: results.map((result) => result.internalDegasserPumpPressure),
                        fill: false,
                        // borderColor: "rgb(192, 75, 192)",
                        tension: 0.1,
                    },
                    {
                        label: "External Degasser Pump Pressure",
                        data: results.map((result) => result.externalDegasserPumpPressure),
                        fill: false,
                        // borderColor: "rgb(75, 192, 192)",
                        tension: 0.1,
                    }
                ],
            }

            const pressureChartOptions = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Reading index",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Pressure (mBar)",
                        },
                    },
                },
                animation: {
                    duration: 0,
                  },
            };

            const effortChartData = {
                labels: Array.from(results.keys()),

                datasets: [
                    {
                        label: "Flow Effort",
                        data: results.map((result) => result.flowEffort),
                        fill: false,
                        //   borderColor: "rgb(192, 192, 75)",
                        tension: 0.1,
                    },
                    {
                        label: "Degasser Effort",
                        data: results.map((result) => result.degasserEffort),
                        fill: false,
                        //   borderColor: "rgb(192, 192, 75)",
                        tension: 0.1,
                    },
                ],
            }

            const effortChartOptions = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Reading index",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Effort (%)",
                        },
                    },
                },
                animation: {
                    duration: 0,
                  },
            };
            const pressureChartContext = ((pressureChartContextRef.current) as any).getContext("2d");
            pressureChartRef.current = (new Chart(pressureChartContext, {
                type: "line",
                data: pressureChartData,
                options: pressureChartOptions,
            }) as any)

            const effortChartContext = ((effortChartContextRef.current) as any).getContext("2d");
            effortChartRef.current = (new Chart(effortChartContext, {
                type: "line",
                data: effortChartData,
                options: effortChartOptions,
            }) as any)
            // Cleanup function to destroy the chart
            // and avoid errors of the form:
            // Canvas is already in use. Chart with ID '0' must be destroyed before the canvas with ID 'pressureChartRef' can be reused.
            return () => {
                if (pressureChartRef.current) {
                    (pressureChartRef.current as any).destroy();
                    pressureChartRef.current = null;
                }
                if (effortChartRef.current) {
                    (effortChartRef.current as any).destroy();
                    effortChartRef.current = null;
                }
            }
        }
    }, [resultFile]);

    let contentToRender = <></>;
    switch (resultFile.type) {
        case DownloadStatusTypeString.LOADING:
            contentToRender = <Alert variation="info">Loading...</Alert>
            break;
        case DownloadStatusTypeString.ERROR:
            contentToRender = <Alert variation="error">{resultFile.errorString}</Alert>
            break;
        case DownloadStatusTypeString.LOADED:
            const results = resultFile.results
            contentToRender = (
                <Grid columnGap="0.5rem" rowGap="0.5rem" templateColumns="auto 1fr" templateRows="auto 1fr 1fr">
                    <Card columnStart="1" columnEnd="-1">
                        <Grid templateColumns="auto 1fr">
                            <Card columnStart="1" columnEnd="1">
                                <BackLink />
                            </Card>
                            <Card columnStart="2" columnEnd="2">
                                <Heading level={3}>Pressure Rig {results.type} {results.subType ?? ''} on {results.date}</Heading>
                            </Card>
                        </Grid>
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                        <Heading level={4}>Pressure</Heading>
                        <canvas id="pressureChartRef" ref={pressureChartContextRef} width={400} height={100} />
                    </Card>
                    <Card columnStart="1" columnEnd="-1">
                        <Heading level={4}>Effort</Heading>
                        <canvas id="effortChartRef" ref={effortChartContextRef} width={400} height={100} />
                    </Card>
                </Grid>
            );
            break;
    }

    return (
        <View>
            {contentToRender}
        </View>
    );
}  