import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";

export class TriggerError extends ValidationError {}

/**
 * Checks if all triggers have valid values that can exist in the runtime of the
 * assay.
 * @param configuration The configuration.
 */
export const triggersCorrect = (configuration: Configuration): ValidationError[] => {
    if (configuration.calculatedValues.length === 0) {
        return []
    }

    const errors: ValidationError[] = []

    configuration.calculatedValues.forEach((val) => {
        const trigger = val.trigger

        if (trigger === undefined) {
            errors.push(new TriggerError(`Calculated value "${val.name}" has no trigger`))
            return
        }
        
        const globals = [
            "flowEffort",
            "flowPressure",
            "degasserEffort",
            "degasserPressure",
            "time"
        ]

        if (globals.includes(trigger)) {
            return
        }

        // Generated Values:
        // calculatedValue_time_elapsed
        const calculatedValueGeneratedValues = configuration.calculatedValues.map((val) => `${val.name}_time_elapsed`)
        if (calculatedValueGeneratedValues.includes(trigger)) {
            return
        }

        // sequenceStep__completed
        const sequenceStepsGeneratedValues = configuration.assaySequence.map(
            (seq) => `${seq.name}__completed`
        )
        if (sequenceStepsGeneratedValues.includes(trigger)) {
            return
        }

        // User-Defined Values:
        // channelName_calculatedValue
        const channelGeneratedValues = configuration.calculatedValues.flatMap(
            (val) => configuration.channelConfiguration.map((channel) => `${channel.name}_${val.name}`)
        )
        if (channelGeneratedValues.includes(trigger)) {
            return
        }

        // variableName (from routines)
        // Examples:
        // - numSpotsCaptured
        // - degasserEffortChanged
        // - dryWetChangeDetected
        const routineGeneratedValues = configuration.assaySequence.flatMap(
            (seq) => seq.routines?.map((routine) => routine.variableName) ?? []
        )
        if (routineGeneratedValues.includes(trigger)) {
            return
        }

        let augmentedError = ""
        if (val.trigger.includes(" ")) {
            augmentedError = ` (spaces must be replaced with an underscore '_')`
        }
        errors.push(new TriggerError(`Calculated value "${val.name}" used unknown trigger "${val.trigger}"${augmentedError}`))
    })

    return errors
}
