import { useParams } from "react-router-dom";
import { formatDateYYYYMMDD } from '../utilities/DateUtilities'

export type RequestType = {
    rigType: string 
    dateString: string // format: YYYY-MM-DD  
}

export const defaultRigType = "chipflow"
export const defaultDateString = formatDateYYYYMMDD(new Date())

export const useRequestFromURLParams = (): RequestType => {
    const { rigType, dateString } = useParams();
    const request: RequestType = {
      rigType: rigType ?? defaultRigType,
      dateString: dateString ?? defaultDateString
    }
    return request
}
