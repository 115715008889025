import { Flex, View, Heading, Input, Menu, MenuItem, Divider } from "@aws-amplify/ui-react";
import { signOut } from 'aws-amplify/auth';
import { RequestType } from '../hooks/useRequestType';
import { formatDateYYYYMMDD } from "../utilities/DateUtilities";
import { useNavigate } from "react-router-dom";

export const TopbarNav = ({ request }: { request: RequestType }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate()

    const setDate = (e: any) => {
        const date = new Date(e.target.value)
        if (!isNaN(date.getTime())) { // check for Safari Nan issue and only the set the date if valid
            pushNewHistoryPath(formatDateYYYYMMDD(date))
        }
    }

    const pushNewHistoryPath = (dateString: string) => {
        navigate(`/overview/${request.rigType}/${dateString}`)
    }

    return (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
        >
            <Heading level={3}>M3&nbsp;Server</Heading>
            <Input name="datePicker" id="datePicker" type="date" placeholder="yyyy-mm-dd" value={request.dateString} onChange={e => setDate(e)} />
            <View className='push-right'> </View>
            <Menu menuAlign="start">
                <MenuItem onClick={() => window.location.href = "https://documentation.m3handheld.com"}>
                    Documentation
                </MenuItem>
                <MenuItem onClick={() => window.location.href = "https://github.com/Attomarker-Ltd/m3handheldserver/tree/main/m3server-reactapp"}>
                    Github source for this site
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => signOut()}>
                    Logout
                </MenuItem>
            </Menu>
        </Flex>
    );
}