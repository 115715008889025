import React from 'react'
import { list } from 'aws-amplify/storage';
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'
import { configAmplifyForConfigurationsBucket, configAmplifyForUploadBucket } from '../authentication/amplifyV6-azure-cognito-configuration'

type FetchConfigurationFilesList = DownloadStatusType<ConfigurationFileType[]>

export type ConfigurationFileType = {
    name: string
    lastUpdated: Date
}

export const useConfigurationFiles = (configurationIndex: number, path: string = 'active/'): FetchConfigurationFilesList => {
    const [configurationFilesList, setConfigurationFilesList] = React.useState<FetchConfigurationFilesList>({type: DownloadStatusTypeString.LOADING})

    React.useEffect(() => {
        const fetchFileList = async () => {
            setConfigurationFilesList({type: DownloadStatusTypeString.LOADING})
            try {
                configAmplifyForConfigurationsBucket()
                const listResult = await list({prefix: path})
                const configurations = listResult.items.map(item => {
                    const filepath = item.key as string
                    const fileWithPath = filepath.replace(path, "")
                    const fileWithoutJson = fileWithPath.replace(".json", "");
                    return {
                        name: fileWithoutJson,
                        lastUpdated: item.lastModified as Date
                    }
                })
                const filteredConfigurations = configurations.filter(config => config.name !== "")
                setConfigurationFilesList({type: DownloadStatusTypeString.LOADED, results: filteredConfigurations})
            } catch (error: any) {
                setConfigurationFilesList({type: DownloadStatusTypeString.ERROR, errorString: error.toString()})
            } finally {
                configAmplifyForUploadBucket() // restore the original S3 bucket configuration
            }
        }
        fetchFileList()
    }, [configurationIndex, path])

    return configurationFilesList
}