import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef } from "react";
import { DownloadStatusType, DownloadStatusTypeString } from "../../utilities/DownloadStatusType";
import { PersistedVariablesStep } from "../../types/flowTestResult";

Chart.register(annotationPlugin)

export const EquationChart = ({ resultFile }: {resultFile: DownloadStatusType<[PersistedVariablesStep]>}) => {
    const chartContextRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (resultFile.type === DownloadStatusTypeString.LOADED) {
            const data = resultFile.results

            const channelData: { [channel: string]: number } = data.at(-1)!.equationResults

            const dataset = [
                {
                    label: "Result",
                    data: Object.values(channelData),
                    yAxisID: 'y',
                    borderWidth: 1,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 205, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(201, 203, 207, 0.2)'
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(201, 203, 207)'
                    ],
                }
            ]

            const chartData = {
                labels: Object.keys(channelData),
                datasets: dataset
            }

            const chartOptions = {
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: "Channel",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Result",
                        },
                    }
                },
                animation: {
                    duration: 0,
                },
                plugins: {
                    annotation: {}
                }
            };

            const chartContext = ((chartContextRef.current) as any).getContext("2d");
            chartRef.current = (new Chart(chartContext, {
                type: "bar",
                data: chartData,
                options: chartOptions as any,
            }) as any)

            return () => {
                if (chartRef.current) {
                    (chartRef.current as any).destroy();
                    chartRef.current = null;
                }
            }
        }
    }, [resultFile]);

    return <canvas id="chartRef" ref={chartContextRef} width={400} height={100} />
}
