import { Chart } from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef } from "react";
import { DownloadStatusType, DownloadStatusTypeString } from "../../utilities/DownloadStatusType";
import { FlowTestResult } from "../../types/flowTestResult";

Chart.register(annotationPlugin)

export const PumpChart = ({ resultFile }: {resultFile: DownloadStatusType<FlowTestResult>}) => {
    const chartContextRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        if (resultFile.type === DownloadStatusTypeString.LOADED) {
            const data = resultFile.results

            let dataset = [
                {
                    label: "Internal Flow Pump Pressure (mBar)",
                    data: data.readings.map(item => item.flowPump),
                    fill: false,
                    // borderColor: "rgb(75, 192, 192)",
                    tension: 0.1,
                    yAxisID: 'y',
                },
                {
                    label: "External Flow Meter flow rate * 10 (ml/s * 10)",
                    data: data.readings.map(item => item.flowMeter * 10),
                    fill: false,
                    // borderColor: "rgb(192, 75, 192)",
                    tension: 0.1,
                    // yAxisID: 'y1',
                    // position: 'right',
                    // display: true,
                    
                    grid: {
                              drawOnChartArea: false, // only want the grid lines for one axis to show up
                            }
                }
            ]

            if (data.readings[0].degasserPump !== undefined) {
                dataset.push(
                    {
                        label: "Internal Degasser Pump Pressure (mBar)",
                        data: data.readings.map(item => item.degasserPump!),
                        fill: false,
                        // borderColor: "rgb(192, 192, 75)",
                        tension: 0.1,
                        yAxisID: 'y',
                    }
                )
            }

            if (data.readings[0].flowEffort !== undefined) {
                dataset.push(
                    {
                        label: "Internal Flow Pump Effort",
                        data: data.readings.map(item => item.flowEffort!),
                        fill: false,
                        // borderColor: "rgb(192, 192, 75)",
                        tension: 0.1,
                        yAxisID: 'y',
                    }
                )
            }

            if (data.readings[0].degasserEffort !== undefined) {
                dataset.push(
                    {
                        label: "Internal Degasser Pump Effort",
                        data: data.readings.map(item => item.degasserEffort!),
                        fill: false,
                        // borderColor: "rgb(192, 192, 75)",
                        tension: 0.1,
                        yAxisID: 'y',
                    }
                )
            }

            const chartData = {
                labels: data.readings.map(item =>item.time),
                datasets: dataset
            }

            const chartOptions = {
                scales: {
                    // xAxes: [{
                    //     type: 'linear',
                    //     position: 'bottom'
                    //   }],
                    x: {
                        // type: 'linear',
                        title: {
                            display: true,
                            text: "Time (ms)",
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: "Pressure (mBar)",
                        },
                    },
                    // y1: {
                    //     // type: 'linear',
                    //     display: true,
                    //     position: 'right',
                
                    //     // grid line settings
                    //     grid: {
                    //       drawOnChartArea: false, // only want the grid lines for one axis to show up
                    //     }
                    // }
                },
                animation: {
                    duration: 0,
                  },
                plugins: {
                annotation: {
                    // annotations: {
                    //     box1: {
                    //       // Indicates the type of annotation
                    //       type: 'box',
                    //       xMin: 4000,
                    //       xMax: 8000,
                    //       yMin: 20,
                    //       yMax: 50,
                    //       backgroundColor: 'rgba(255, 99, 132, 0.25)'
                    //     }
                    //   }
                    // annotations: data.stepTimes.map((item, index) => {
                    //   return {
                    //     type: 'line',
                    //     mode: 'vertical',
                    //     scaleID: 'x-axis-0',
                    //     // value: item.stepTime,
                    //     value: 2000 * index,
                    //     borderColor: 'black',
                    //     borderWidth: 2,
                    //     label: {
                    //         display: true,
                    //         backgroundColor: 'black',
                    //         borderColor: 'black',
                    //         borderRadius: 10,
                    //         borderWidth: 2,
                    //         content: item.step.channel,
                    //         rotation: 'auto'
                    //       },
                    //   };
                    // })
                  }
                }
            };

            const chartContext = ((chartContextRef.current) as any).getContext("2d");
            chartRef.current = (new Chart(chartContext, {
                type: "line",
                data: chartData,
                options: chartOptions as any,
            }) as any)

            // Cleanup function to destroy the chart
            // and avoid errors of the form:
            // Canvas is already in use. Chart with ID '0' must be destroyed before the canvas with ID 'pressureChartRef' can be reused.
            return () => {
                if (chartRef.current) {
                    (chartRef.current as any).destroy();
                    chartRef.current = null;
                }
            }
        }
    }, [resultFile]);

    return <canvas id="chartRef" ref={chartContextRef} width={400} height={100} />
}
