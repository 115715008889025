export enum DownloadStatusTypeString {
    LOADING = "loading",
    ERROR = "error",
    LOADED = "loaded"
}

export type DownloadStatusIsLoadingType = {
    type: DownloadStatusTypeString.LOADING
}

export type DownloadStatusLoadingErrorType = {
    type: DownloadStatusTypeString.ERROR
    errorString: string 
}


export type DownloadStatusLoadedDataType<T> = {
    type: DownloadStatusTypeString.LOADED
    results: T
}

export type DownloadStatusType<T> = DownloadStatusIsLoadingType | DownloadStatusLoadingErrorType | DownloadStatusLoadedDataType<T>
