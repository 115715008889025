import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";

export const emptyStrings = (configuration: Configuration): ValidationError[] => {
    const errors: ValidationError[] = []

    configuration.assaySequence.forEach(sequence => {
        if (sequence.name.length === 0) {
            errors.push(new ValidationError(`Sequence name cannot be empty`))
        }

        if (
            typeof sequence.degasserPressure === "string" &&
            sequence.degasserPressure.length === 0
        ) {
            errors.push(new ValidationError(`Sequence "${sequence.name}"'s degasserPressure value cannot be empty`))
        }

        if (
            typeof sequence.escapeWhen === "string" &&
            sequence.escapeWhen.length === 0
        ) {
            errors.push(new ValidationError(`Sequence "${sequence.name}"'s escapeWhen value cannot be empty`))
        }

        if (
            typeof sequence.flowPressure === "string" &&
            sequence.flowPressure.length === 0
        ) {
            errors.push(new ValidationError(`Sequence "${sequence.name}"'s flowPressure value cannot be empty`))
        }

        sequence.routines?.forEach(routine => {
            if (routine.routineName.length === 0) {
                errors.push(new ValidationError(`Routine name cannot be empty`))
            }

            if (routine.variableName.length === 0) {
                errors.push(new ValidationError(`Routine "${routine.routineName}"'s variableName cannot be empty`))
            }

            for (const key in routine.parameters) {
                if (key.length === 0) {
                    errors.push(new ValidationError(`Routine "${routine.routineName}"'s parameter name cannot be empty`))
                }
            }
        })
    })

    configuration.calculatedValues.forEach(calculatedValue => {
        if (calculatedValue.name.length === 0) {
            errors.push(new ValidationError(`Calculated value name cannot be empty`))
        }

        if (calculatedValue.trigger.length === 0) {
            errors.push(new ValidationError(`Calculated value "${calculatedValue.name}"'s trigger cannot be empty`))
        }

        if (calculatedValue.type.length === 0) {
            errors.push(new ValidationError(`Calculated value "${calculatedValue.name}"'s type cannot be empty`))
        }
    })

    configuration.channelConfiguration.forEach(channel => {
        if (channel.name.length === 0) {
            errors.push(new ValidationError(`Channel name cannot be empty`))
        }

        if (channel.calibratedEquation?.length === 0) {
            errors.push(new ValidationError(`Channel "${channel.name}"'s calibratedEquation cannot be empty`))
        }

        if (channel.verboseName?.length === 0) {
            errors.push(new ValidationError(`Channel "${channel.name}"'s verboseName cannot be empty`))
        }

        if (channel.units?.length === 0) {
            errors.push(new ValidationError(`Channel "${channel.name}"'s units cannot be empty`))
        }
    })

    return errors
}
