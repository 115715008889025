// largely copied from: https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-react/src/withAuthenticator.tsx
// Why? I couldn't work out how to customise the standard `withAuthenticator` to work with Azure authentication
// Based on ideas from here: https://docs.amplify.aws/javascript/build-a-backend/auth/add-social-provider/

import React, { ComponentType, FunctionComponent } from 'react';

import { signInWithRedirect, getCurrentUser } from 'aws-amplify/auth';
import { configAmplifyForUploadBucket } from './amplifyV6-azure-cognito-configuration'
import { View, Heading, Text, useTheme, Button } from "@aws-amplify/ui-react";

// import { ConsoleLogger } from 'aws-amplify/utils';
// ConsoleLogger.LOG_LEVEL = 'VERBOSE';

configAmplifyForUploadBucket()


export function withM3AmplifyV6AzureAuthenticator(Component: ComponentType) {
	const AppWithAuthenticator: FunctionComponent = props => {
		const [signedIn, setSignedIn] = React.useState(false);
		const { tokens } = useTheme();

		React.useEffect(() => {
			const checkIfUserLoggedIn = async () => {
		        try {
					const currentUser = await getCurrentUser();
					if (!currentUser?.username) {
						return false; 
					}
		          	return true;
		        } catch (error: any) {
					console.error(error)
		          	return false
		        }
		    }
			checkIfUserLoggedIn().then((isLoggedIn) => setSignedIn(isLoggedIn))
		}, []);

		if (!signedIn) {
			return (
				<View paddingTop={100}>
					<View textAlign="center" padding={tokens.space.large}>
						<Heading level={1}>M3 Server</Heading>
						<Text color={tokens.colors.neutral[80]}>Use your Attomarker login</Text>
						<Text color={tokens.colors.neutral[80]}>(the same login you use for <a href="https://attomarker.sharepoint.com">Sharepoint</a>)</Text>
					</View>
					<View textAlign="center" padding={tokens.space.large}>
					<Button variation="primary" onClick={() => signInWithRedirect()}>Log in</Button>
					</View>
					<View textAlign="center" padding={tokens.space.large}>
						<Text color={tokens.colors.neutral[80]}>
							Attomarker & Carus &copy; All Rights Reserved
						</Text>
					</View>
				</View>
			)
		}
		return <Component />;
	};

	return AppWithAuthenticator;
}
