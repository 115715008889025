import { Configuration } from "../../../types/Configuration";
import { ValidationError } from "../ValidationError";

export class UniqueNameError extends ValidationError {}

/**
 * Checks if assay sequence names, calculated values, and channels, all have
 * unique names.
 * @param configuration The configuration.
 */
export const uniqueNames = (configuration: Configuration): ValidationError[] => {
    if (configuration.assaySequence.length === 0) {
        return [new UniqueNameError("Assay sequence was empty")]
    }

    const errors: ValidationError[] = []

    const existingNames = new Set()
    configuration.assaySequence.forEach(seq => {
        const name = seq.name

        if (existingNames.has(name)) {
            errors.push(new UniqueNameError(`Sequence name "${seq.name}" was already taken by another sequence`))
        } else {
            existingNames.add(name)
        }
    })

    existingNames.clear()
    configuration.calculatedValues.forEach(val => {
        const name = val.name

        if (existingNames.has(name)) {
            errors.push(new UniqueNameError(`Calculated value name "${name}" was already taken by another calculated value`))
        } else {
            existingNames.add(name)
        }
    })

    existingNames.clear()
    configuration.channelConfiguration.forEach(channel => {
        const name = channel.name

        if (existingNames.has(name)) {
            errors.push(new UniqueNameError(`Channel name "${name}" was already taken by another channel`))
        } else {
            existingNames.add(name)
        }
    })

    return errors
}