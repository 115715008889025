import { configAmplifyForConfigurationsBucket, configAmplifyForUploadBucket } from '../authentication/amplifyV6-azure-cognito-configuration'
import { copy } from 'aws-amplify/storage';

export const createNewConfiguration = async (newConfigurationFilename: string, basedOnFilename: string) => {
    try {
        configAmplifyForConfigurationsBucket()
        await copy({
            source: { key: `active/${basedOnFilename}.json`},
            destination: { key: `active/${newConfigurationFilename}.json` }
        })
    } catch (error: any) {
        console.error("error: ", error)
    } finally {
        configAmplifyForUploadBucket() // restore the original S3 bucket configuration
    }
}