import '../app/App.css';
import '@aws-amplify/ui-react/styles.css';
import { Table, TableHead, TableBody, TableCell, TableRow, Alert } from "@aws-amplify/ui-react";
import { DownloadStatusTypeString } from "../utilities/DownloadStatusType";
import { RequestType, useRequestFromURLParams } from "../hooks/useRequestType";
import { Link } from "react-router-dom";
import { useListResultFiles } from "../hooks/useListResultFiles";
import { camelCaseToSpaced } from "../utilities/StringUtilities";

export const OverviewFileList = () => {
    const request = useRequestFromURLParams() 
    const filesList = useListResultFiles(request.rigType, request.dateString)
  
    let contentToRender = <></>
  
    switch (filesList.type) {
      case DownloadStatusTypeString.LOADING:
        contentToRender = <Alert variation="info">Loading...</Alert>
        break;
      case DownloadStatusTypeString.ERROR:
        contentToRender = <Alert variation="error">{filesList.errorString}</Alert>
        break;
      case DownloadStatusTypeString.LOADED:
        if (filesList.results.length === 0) {
          contentToRender = <Alert variation="info">No data uploaded on {request.dateString}</Alert>
        } else {
          contentToRender = <FileList filepaths={filesList.results} request={request} />
        }
        break;
    }
  
    return contentToRender
}
  
  const FileList = ({filepaths, request} : {filepaths: string[], request: RequestType}) => {
    const rigType = camelCaseToSpaced(request.rigType)
    let links = filepaths.map((filepath) => {
      const parts = filepath.split("/")
      const filenameParts = parts[parts.length - 1].split("-")
      const timeOfTest = `${filenameParts[filenameParts.length - 3]}:${filenameParts[filenameParts.length - 2]}:${filenameParts[filenameParts.length - 1]}`
      return <TableRow key={filepath}>
        <TableCell>
          <Link to={`/result/${filepath}`}>{timeOfTest}</Link>
        </TableCell>
        <TableCell>{rigType}</TableCell>
      </TableRow>
    })
    return <Table variation="striped" highlightOnHover={true}>
      <TableHead>
        <TableRow>
          <TableCell as="th">Time of Test</TableCell>
          <TableCell as="th">Rig</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {links}
      </TableBody>
      </Table>
  }
