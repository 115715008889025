import React from 'react'
import { list } from 'aws-amplify/storage';
import { DownloadStatusType, DownloadStatusTypeString } from '../utilities/DownloadStatusType'

type FetchTestConfigurationPath = DownloadStatusType<string>

export const useTestConfigurationPath = (date: string | undefined, path: string | undefined): FetchTestConfigurationPath => {
    const [testConfigurationPath, setTestConfigurationPath] = React.useState<FetchTestConfigurationPath>({type: DownloadStatusTypeString.LOADING})

    // given: filename: "chipflowTest-2024-07-08-13-55-20" and  dateString: "2024-07-08" extract "2024-07-08-13-55-20"
    function extractDateTime(filename: string, dateString: string) {
        const startIndex = filename.indexOf(dateString);
        if (startIndex !== -1) {
            return filename.substring(startIndex);
        }
        return '';
    }

    React.useEffect(() => {
        const fetchPotentialConfigurationFile = async (dateTime: string) => {
            setTestConfigurationPath({type: DownloadStatusTypeString.LOADING})
            try {
                const pathNoFilename = path!.substring(0, path!.lastIndexOf('/'));
                const matchingPathsResult = await list({prefix: `${pathNoFilename}/configuration-${dateTime}`})
                if (matchingPathsResult.items.length !== 0) {
                    setTestConfigurationPath({type: DownloadStatusTypeString.LOADED, results: matchingPathsResult.items[0].key})

                } else {
                    setTestConfigurationPath({type: DownloadStatusTypeString.ERROR, errorString: `No matching paths found for: '${path}/configuration-${date}*'`})
                }
            } catch (error: any) {
                setTestConfigurationPath({type: DownloadStatusTypeString.ERROR, errorString: error.toString()})
            } 
        }
        if (date === undefined || path === undefined) {
            return setTestConfigurationPath({type: DownloadStatusTypeString.ERROR, errorString: "date and path must be defined"})
        } else {
            const filename = path.split('/').pop()
            const dateTime = extractDateTime(filename!, date)
            fetchPotentialConfigurationFile(dateTime)
        }
    }, [date, path])

    return testConfigurationPath
}