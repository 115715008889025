import { validateConfiguration, Configuration } from "../../../types/Configuration"
import { ValidationError } from "../ValidationError"

export class JsonParseError extends ValidationError {
    constructor(cause: any) {
        super("The configuration file was incorrectly formatted.", {cause,})
    }
}

export class ConfigurationFormatError extends ValidationError {}

/**
 * Checks that the file contents parses as json and that it is a valid format
 * to match Configuration's requirements.
 * @param fileContents The file contents of the json.
 * @returns The parsed json object if successful, or a list of validation errors.
 * @throws JsonParseError The file contents were not in a valid .json format.
 */
export const jsonParses = (fileContents: string): Configuration | ValidationError[] => {
    // Validate if contents can be parsed as json
    try {
        const configuration = JSON.parse(fileContents)
        const errors = validateConfiguration(configuration).map((error) => new ConfigurationFormatError(error))
        return errors.length === 0 ? configuration : errors
    } catch (error) {
        throw new JsonParseError(error)
    }
}
